'use client';

import {
  memo,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Fire } from '~/fire/Fire';
import { Slider } from '../ui/slider';

const FireLeft = ({ fuelLeft }: any) => {
  return <div className="w-80 text-center">Fuel left: {fuelLeft}</div>;
};
const BurnRateControl = ({ fireRef }: any) => {
  return (
    <div className="w-80">
      <Slider
        defaultValue={[50]}
        max={100}
        step={1}
        onValueCommit={(value) => {
          fireRef.current?.setBurnRate(value[0]);
        }}
      />
    </div>
  );
};

export const FireWrapper = () => {
  const fireRef = useRef<null | Fire>(null);
  const [fuelLeft, setFuelLeft] = useState(500);
  const onFuelChange = useCallback((fuelLeft: number) => {
    setFuelLeft(Math.ceil(fuelLeft));
  }, []);

  useEffect(() => {
    fireRef.current = new Fire('fire-canvas', { onFuelChange });
  }, [onFuelChange]);

  return (
    <>
      <FireLeft fuelLeft={fuelLeft} />
      <BurnRateControl fireRef={fireRef} />
      <FireComponent fireRef={fireRef} />
    </>
  );
};

export const FireComponent = memo(
  ({ fireRef }: { fireRef: RefObject<Fire | null> }) => {
    return (
      <div>
        <canvas id="fire-canvas" width="320px" height="480px"></canvas>

        <div className="flex justify-between mt-4">
          <button
            onClick={() => fireRef.current?.start()}
            type="button"
            className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            Start the fire!
          </button>
          <button
            onClick={() => fireRef.current?.addFuel(500)}
            type="button"
            className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            Add fuel
          </button>
          <button
            onClick={() => fireRef.current?.boost()}
            type="button"
            className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
          >
            Boost!
          </button>
        </div>
      </div>
    );
  }
);

FireComponent.displayName = 'FireComponent';
