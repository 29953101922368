/**
 * Very basic string formatter for positional and non-positional arguments,
 * python String.format style.
 *
 * Examples:
 *
 *   _c.fmt("{}, {}!", "Hello", "world");
 *   _c.fmt("Someone please call {0} {0} {1}", "one", "two");
 *
 */
export const fmt = (...args: any[]) => {
  let str = args[0];
  let i;
  let r;
  for (i = 1; i < args.length; i++) {
    r = new RegExp(`\\{${i - 1}\\}`, 'g');
    if (r.test(str)) {
      r.lastIndex = 0; // Reset regexp
      str = str.replace(r, String(args[i]));
    } else {
      str = str.replace('{}', String(args[i]));
    }
  }
  return str;
};
