import { Square } from './interfaces';
import { Marchingsquares } from './Marchingsquares';
import { Sky } from './Sky';

type FireConfig = {
  layers: { color: string; intensity: number }[];
  fuel: number;
  // amount of fuel burnt per second
  burnRate: number;
  onFuelChange: (fuelLeft: number) => void;
};

// Base fuel unit = 500

// Time to burn 500 fuel
// burnRate: 20, time: 60 sec
// burnRate: 21, time: 30 sec
// burnRate: 22, time: 15 sec

export class Fire {
  properties = [
    {
      key: 'optimize_onlysquares',
      label: 'Only squares',
      type: 'boolean',
    },
    {
      key: 'optimize_rle_squares',
      label: 'Grouped square paint',
      type: 'boolean',
    },
    {
      key: 'optimize_removedoubles',
      label: 'Remove doubles',
      type: 'boolean',
    },
    {
      key: 'optimize_batchfill',
      label: 'Batch fill',
      type: 'boolean',
    },
    {
      key: 'debug_drawmesh',
      label: 'Show grid',
      type: 'boolean',
    },
    {
      key: 'useintensitymodulation',
      label: 'Use intensity modulation',
      type: 'boolean',
    },
    'x',
    'y',
    'zindex',
    'width',
    'height',
    'blocksize',
    {
      key: 'runsimulation',
      label: 'Run fire simulation',
      type: 'boolean',
    },
    {
      key: 'dopaint',
      label: 'Draw fire',
      type: 'boolean',
    },
    {
      key: 'burnfactor',
      max: 22,
      min: 20,
    },
  ];

  label = 'Fire using marching squares';
  x = 0;
  y = 0;
  height = 81;
  zindex = 1;
  width = 55;
  blocksize = 6;
  // 20..22
  burnRate = 21;
  fuel = 500;

  runsimulation = false;
  useintensitymodulation = 0;
  dopaint = 1;
  optimize_onlysquares = false;
  optimize_rle_squares = true;
  optimize_removedoubles = false;
  optimize_batchfill = true;
  debug_drawmesh = false;
  isRunning = false;

  config: FireConfig = {
    layers: [
      { color: '#ffffff', intensity: 110 },
      { color: '#E9F23F', intensity: 64 },
      { color: '#e27023', intensity: 35 },
      { color: '#9b3513', intensity: 22 },
      { color: '#770000', intensity: 11 },
    ],
    fuel: 100,
    burnRate: 1,
    onFuelChange: () => {},
  };
  ctx: CanvasRenderingContext2D;
  grid: number[][];
  private boostRate = 0;
  burnoffset: number;
  mytick: number;
  ms = 0;

  constructor(canvasId: string, config: Partial<FireConfig> = {}) {
    this.grid = [];
    this.burnoffset = 0;
    this.mytick = 60;

    this.config = {
      ...this.config,
      ...config,
    };

    this.ctx = this.getCanvasContext(canvasId);
  }

  private getCanvasContext(canvasId: string) {
    const canvas = document.getElementById(canvasId);
    if (canvas instanceof HTMLCanvasElement === false) {
      throw new Error('Canvas not supported!');
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('Canvas not supported!');
    }

    return ctx;
  }

  private fillBackground() {
    const backgroundFill = new Sky({ light: 80 }).getFill(this.ctx);

    var width = this.ctx.canvas.width;
    var height = this.ctx.canvas.height;
    this.ctx.beginPath();
    this.ctx.rect(0, 0, width, height);
    this.ctx.fillStyle = backgroundFill;
    this.ctx.fill();
  }

  updateBurnOffset() {
    this.mytick = ((this.mytick || 0) + 0.4) % 100;
    if (this.mytick > 85) {
      this.burnoffset += Math.random() * 0.6;
    }
    if (this.mytick < 50) {
      this.burnoffset *= 0.95;
    }
  }

  report() {}

  paint() {
    let i;
    let color;
    let g;
    let line;
    let point;
    let startx;
    let width;
    let x;
    let y;
    let grids = [];

    if (!this.dopaint) {
      return;
    }

    this.ctx.translate(this.x, this.y);

    for (const layer of this.config.layers) {
      grids.push(
        Marchingsquares.calculateAllWithInterpolation(
          this.grid,
          layer.intensity / 100
        )
      );
    }

    if (this.optimize_removedoubles) {
      this.erasePaintDoubles(grids);
    }

    for (let i = this.config.layers.length - 1; i >= 0; i--) {
      const { color } = this.config.layers[i];

      this.ctx.fillStyle = color;
      if (this.optimize_batchfill) {
        this.ctx.beginPath();
      }
      g = grids[i];

      for (y = 0; y < g.length; y++) {
        line = g[y];
        for (x = 0; x < line.length; x++) {
          point = line[x];
          if (point && point.length > 0) {
            // RLE squares
            if (this.optimize_rle_squares) {
              if (point[0].fullsquare) {
                startx = x;
                width = 0;
                while (
                  x < line.length &&
                  line[x] &&
                  line[x][0] &&
                  line[x][0].fullsquare
                ) {
                  x++;
                  width++;
                }
                if (width > 0) {
                  if (startx + width >= line.length) {
                    width = line.length - startx - 1;
                  }
                  this.drawRect(
                    this.ctx,
                    startx,
                    y,
                    width + 1,
                    1,
                    this.optimize_batchfill
                  );
                  continue;
                }
              }
            }
            if (this.optimize_onlysquares) {
              this.drawRect(this.ctx, x, y, 1, 1, this.optimize_batchfill);
            } else {
              this.drawContour(this.ctx, point, x, y, this.optimize_batchfill);
            }
          }
        }
      }
      if (this.optimize_batchfill) {
        this.ctx.fill();
      }
    }

    if (this.debug_drawmesh) {
      this.drawMesh(this.ctx, grids[0][0].length, grids[0].length);
    }
    this.ctx.translate(-this.x, -this.y);
  }

  erasePaintDoubles(grids: any) {
    let x;
    let y;
    let ymax = grids[0].length;
    let xmax = grids[0][0].length;
    let painted;
    let cont;
    let i;

    for (y = 0; y < ymax; y++) {
      for (x = 0; x < xmax; x++) {
        painted = false;
        for (i = 0; i < grids.length; i++) {
          if (painted) {
            grids[i][y][x] = null;
          } else {
            cont = grids[i][y][x];
            if (cont[0] && cont[0].fullsquare) {
              painted = true;
            }
          }
        }
      }
    }
  }

  drawMesh(ctx: CanvasRenderingContext2D, width: number, height: number) {
    let b = this.blocksize;
    let y;
    let x;

    ctx.lineWidth = 0.5;
    ctx.strokeStyle = '#888';
    ctx.beginPath();

    for (y = 0; y <= height; y++) {
      ctx.moveTo(0, 0 + y * b);
      ctx.lineTo(0 + width * b, 0 + y * b);
      ctx.stroke();
    }

    for (x = 0; x <= width; x++) {
      ctx.moveTo(x * b, 0);
      ctx.lineTo(x * b, b * height);
    }
    ctx.stroke();
  }

  drawRect(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height = 1,
    dontFill: boolean
  ) {
    let xoffset;
    let yoffset;
    let b = this.blocksize;
    xoffset = b * x;
    yoffset = b * y;
    if (!dontFill) {
      ctx.beginPath();
    }
    ctx.rect(xoffset, yoffset, width * b, height * b);
    if (!dontFill) {
      ctx.fill();
    }
  }

  drawContour(
    ctx: CanvasRenderingContext2D,
    cont: Square[],
    x: number,
    y: number,
    dontFill: boolean
  ) {
    let xoffset;
    let yoffset;
    let xx;
    let yy;
    let b = this.blocksize;
    let j;

    if (!dontFill) {
      ctx.beginPath();
    }
    xoffset = b * x;
    yoffset = b * y;
    for (j = 0; j < cont.length; j++) {
      xx = cont[j].x;
      yy = cont[j].y;
      if (j === 0) {
        ctx.moveTo(xoffset + xx * b, yoffset + yy * b);
      } else {
        ctx.lineTo(xoffset + xx * b, yoffset + yy * b);
      }
    }
    ctx.closePath();
    if (!dontFill) {
      ctx.fill();
    }
  }

  boost() {
    this.boostRate += 5;
    this.fuel -= 10;
    this.config.onFuelChange(this.fuel);
  }

  /**
   * This is where the actual simulation happens.
   * Basically interates over a grid of cells with different heat or
   * intensity. Each cell gets its intensity updated using its current value,
   * the neighbours on the side and below it, and a random value.
   *
   * The bottom line of cells in the grid gets updated with a randomly
   * initialized offline/offscreen line.
   */
  tick(diff: number) {
    var x;
    var y;
    var lower;
    var upper;
    var lastLine;
    var burnfactor =
      (this.burnRate +
        (this.useintensitymodulation ? this.burnoffset : 0) +
        this.boostRate) /
      100;

    var TICK_MS = 25;

    if (!this.runsimulation) {
      if (this.grid.length < this.height) {
        this.grid = this.createGrid(this.height);
      }
      return;
    }

    this.ms = (this.ms || 0) + diff;

    function processLine(lower: number[], upper: number[]) {
      var n;
      var length = lower.length;
      var sum;
      for (x = 0; x < length; x++) {
        sum = 0;
        n = x - 1;
        sum += (lower[n] || 0) + (lower[n + 1] || 0) + (lower[n + 2] || 0);
        sum += (upper[n] || 0) + (upper[n + 1] || 0) + (upper[n + 2] || 0);
        upper[x] = sum * burnfactor * (0.5 + Math.random() * 0.5);
      }
    }

    if (this.grid.length < this.height) {
      this.grid = this.createGrid(this.height);
    }

    while (this.ms > TICK_MS) {
      for (y = 0; y < this.grid.length - 1; y++) {
        lower = this.grid[y + 1];
        upper = this.grid[y];
        processLine(lower, upper);
      }
      lastLine = this.grid[this.grid.length - 1];
      const newLine = [];
      for (x = 0; x < lastLine.length; x++) {
        const fuelForDisplay = Math.min(this.fuel, 500);
        newLine.push((Math.random() * fuelForDisplay) / 100);
      }
      processLine(newLine, lastLine);
      this.ms = this.ms - TICK_MS;

      if (this.useintensitymodulation) {
        this.updateBurnOffset();
      }
      if (this.boostRate > 0) {
        this.boostRate -= 1;
      }

      this.burnFuel();
    }
  }

  createGrid(height: number) {
    let grid = [];
    let i;

    function makeLine(length: number) {
      let line = [];
      let j;
      for (j = 0; j < length; j++) {
        line.push(0);
      }
      return line;
    }
    for (i = 0; i < height; i++) {
      grid.push(makeLine(this.width));
    }
    return grid;
  }

  draw() {
    this.fillBackground();
    this.paint();
  }

  start() {
    this.runsimulation = !this.runsimulation;
    if (this.isRunning) {
      return;
    }

    this.isRunning = true;

    let lastTs = Date.now();
    let s = 0;
    let frames = 0;

    const animate = () => {
      let curTs = Date.now();
      let diff = curTs - lastTs;
      requestAnimationFrame(animate);
      lastTs = curTs;
      s = s + diff;
      frames++;
      if (s >= 1000) {
        // report FPS
        // console.log(frames);

        s = 0;
        frames = 0;
      }

      this.tick(diff);
      this.draw();
    };

    animate();
  }

  addFuel(amountToAdd: number) {
    this.fuel += amountToAdd;
    this.config.onFuelChange(this.fuel);
  }

  getFuel() {
    return this.fuel;
  }

  setBurnRate(percent: number) {
    this.burnRate = 20 + (2 * percent) / 100;
  }

  private burnFuel() {
    const burnRate = Math.max(0.5, this.burnRate - 20);
    if (this.fuel > 0) {
      const amountToBurn = burnRate * 0.4;
      this.fuel -= amountToBurn;
    }

    this.config.onFuelChange(this.fuel);
  }
}
