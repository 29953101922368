import { fmt } from './utils';

function createNight() {
  return 'rgba(0, 0, 0, 0.4)';
}
function createFill(ctx: CanvasRenderingContext2D, light: number) {
  let l = light * 1.5 - 50;
  let height = ctx.canvas.clientHeight;
  let grd;
  let start;
  let end;
  if (light === 0) {
    return createNight();
  }
  grd = ctx.createLinearGradient(0, 0, 0, height);
  function rgb(r: number, g: number, b: number) {
    const f = 2.55;
    function c(x: number) {
      if (x < 0) {
        return 0;
      }
      if (x > 255) {
        return 255;
      }
      return x;
    }
    r = c(Math.round(r * f));
    g = c(Math.round(g * f));
    b = c(Math.round(b * f));
    return fmt('rgb({}, {}, {})', r, g, b);
  }

  if (l > 74) {
    // Day
    start = rgb(50, 50, 100);
    end = rgb(l, l, 100);
  } else if (l > 50) {
    const f = 86 - l / 2;
    start = rgb(f, f, 100);
    end = rgb(115 - l / 2, l, l + 25);
    // Sunset
  } else if (l > 25) {
    start = rgb(11 + l, 11 + l, 49 + l);
    end = rgb(115 - l / 2, l, l + 25);
    // Sunset
  } else if (l > -25) {
    start = rgb(11 + l, 11 + l, 49 + l);
    end = rgb(115 - l / 2, l, l + 25);
  } else {
    start = rgb(11 + l, 11 + l, 49 + l);
    end = rgb(200 + l * 4, l, l + 25);
  }

  grd.addColorStop(0, start);
  grd.addColorStop(1, end);
  return grd;
}

type SkyConfig = {
  light: number;
};

export class Sky {
  label = 'Sky';
  light = 25;
  properties = [
    {
      key: 'light',
      max: 100,
      min: 0,
    },
  ];
  private fill: CanvasGradient | string | null;

  constructor(config: Partial<SkyConfig> = {}) {
    this.light = config.light || this.light;
    this.fill = null;
  }

  getFill(ctx: CanvasRenderingContext2D) {
    if (!this.fill) {
      this.fill = createFill(ctx, this.light);
    }

    return this.fill;
  }
}
